<template>
  <CommonPages id="libray" title="Ai Novel Online" classs="libray ">
    <div class="library-container pc_padding">
      <div class="site-title m-b-30 m-t-50">
        Library
      </div>
      <div class="library-tag-row">
        <div v-for="(data,i) in tagList" :key="`tag_${i}`" class="library-tag-item cursor"
          :class="{'active':activeTag == data}" @click="seclecTag(data)">
          {{ data }}
        </div>
      </div>

      <div class="library-row pc_padding">
        <div v-for="(data,i) in novelList" :key="`lib-${i}`" class="library-item cursor"
          @click="chooseNoval(data.novelId,data.id,data.novelName)">
          <div class="library-item-img-row">
            <div class="library-item-img-shadow" />
            <img :src="data.bigImg" class="library-item-img" alt="">
            <div class="library-item-info-stars-row">
              <img src="@/assets/star.png" alt=""> {{ data.novelScore }}/10
            </div>
          </div>
          <div class="library-item-title" v-html="data.novelName" />
          <div class="library-item-autor" v-html="data.novelAuthorName" />
          <div class="library-item-type">
            <div class="library-type" v-html="data.novelType" />
          </div>
        </div>
      </div>
    </div>
  </CommonPages>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
import AdComponent from '@/pages/components/AdComponent.vue'
import CommonPages from '@/pages/components/pc_common.vue'

import '@/css/common.scss';

export default {
  name: 'Home',
  components: {
    AdComponent,
    CommonPages,
  },
  mixins: [common],
  data () {
    return {
      novelList: [],
      tagList: null,
      recommondList: [],

      page: 1,   // 当前页数
      hasNext: false,
      activeTag: null,

    };
  },
  metaInfo () {
    return {
      title: 'Library | AiNovelOnline.com',
      meta: [
        {
          name: 'description',
          content: 'Discover a world of AI-generated novels and stories at AiNovelOnline.com. Dive into unique, imaginative tales created by advanced artificial intelligence. Perfect for readers seeking fresh and innovative content.',
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "adsensConfig",
      'adPosConfig',
    ])
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
    // await this.loadAdSenseScript();  // 重新加载广告脚本
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
    this.loadAdSenseScript();  // 重新加载广告脚本
    this.getTagList()
    this.getPageList()

    // 使用防抖包装滚动事件
    this.onScroll = this.debounce(this.onScroll.bind(this), 300);
    // 监听滚动事件
    window.addEventListener('scroll', this.onScroll);
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
    // 组件销毁时移除滚动事件监听
    // window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    //shuju
    async getTagList () {
      try {
        const res = await this.$global.server.getTagList({ noveLang: 'en' })
        console.log(res)
        this.tagList = res.data

      } catch (err) {
        console.log(err)
      }
    },
    async getPageList () {
      const params = {
        novelLang: 'en',
        pageNum: this.page,
        pageSize: 30,
      };
      // 只有当 this.tag 不为 null 时，才添加 tag 字段
      if (this.activeTag !== null) {
        params.novelType = this.activeTag;
      }

      try {
        const res = await this.$global.server.getPageList(params)
        console.log(res)
        if (res.code == 200) {
          let data = res.data.novelList
          this.novelList.push(...data)
          this.hasNext = res.data.hasNext
          this.page++;
        }
      } catch (err) {
        console.log(err)
      }
    },
    seclecTag (tag) {
      if (this.activeTag == tag) return
      console.log(tag);
      this.activeTag = tag
      this.page = 1;   // 当前页数
      this.hasNext = false;
      this.novelList = []
      this.getPageList()
    },
    chooseNoval (name, id) {
      console.log(name, id);
      window.location.href = `/${name}/${id}/0`
    },
    // 防抖函数
    debounce (func, delay) {
      let timeout;
      return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    },
    onScroll () {
      // 获取文档的总高度
      const documentHeight = document.documentElement.scrollHeight;
      // 当前滚动的高度
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      // 视口的高度
      const windowHeight = window.innerHeight;

      // 当滚动距离接近底部2000px时触发
      if (scrollTop + windowHeight >= documentHeight - 2000) {
        console.log('加载');
        this.getPageList();
      }
    },
    //广告相关start
    shouldInsertAd (index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.adPosConfig.home.indexOf(position);

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    shouldDetailInsertAd (index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.adPosConfig.detail.indexOf(position);

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        this.$eventrack('adscript_loaded', 'expose');
        this.loadAdWithDelay()
        return; // 如果已加载，直接返回
      }
      await this.loadScriptConditionally();
    },
    loadScriptConditionally () {
      console.log(this.adsensConfig);
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        this.$eventrack('no_adscript_config', 'expose');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig.scriptUrl}"]`);
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose');
        console.log('脚本已存在，无需重新添加');
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsensConfig?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      this.$eventrack('adscript_add_success', 'expose');
      script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');

    },
    loadAdWithDelay () {
      setTimeout(() => {
        this.displayAd('ads-');
      }, 500);
    },
    async displayAd (tag) {
      await this.$nextTick();

      // 获取所有 ads 元素的 refs，过滤掉非广告的 ref
      const adsElements = Object.entries(this.$refs)
        .filter(([key, ref]) => key.startsWith(tag) && ref !== undefined) // 排除 undefined 的 refs
        .flatMap(([, ref]) => ref); // 展开并获取所有元素

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log("Adsense script not loaded yet, delaying ad display.");
        setTimeout(this.displayAd, 500); // 延迟再次尝试
        return;
      }

      console.log(adsElements); // 检查是否包含 <ins> 标签
      adsElements.forEach(ad => {
        console.log(ad); // 输出每个广告元素
        console.log('ready to push');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      });
    },
    //广告相关end

    //ciyi

    goto (name) {
      this.$router.push({ name: name });
    },

  }
};
</script>
